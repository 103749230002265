<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        {{ questionText }}
      </p>

      <v-checkbox
        v-for="option in options1"
        :key="'pt-1-' + option.value"
        v-model="inputs.input1"
        :value="option.value"
        :label="option.text"
        hide-details
        class="my-1 py-0 pl-6"
        :ripple="false"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'UPEISafety4',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
    };
  },
  computed: {
    questionNumber() {
      return this.taskState.getValueBySymbol('questionNumber').content;
    },
    questionText() {
      if (this.questionNumber.value === 1) {
        return (
          'You will be working with acids and bases in this lab. What safety precautions should be taken' +
          ' when working with these solutions? Check all that apply.'
        );
      } else if (this.questionNumber.value === 2) {
        return 'What clothing and hair restrictions must you follow when attending chemistry lab? Check all that apply.';
      } else if (this.questionNumber.value === 3) {
        return 'Where should lab coats not be worn? Check all that apply';
      } else if (this.questionNumber.value === 4) {
        return 'How do you demonstrate your commitment to lab safety? Check all that apply.?';
      } else {
        return 'Safety mindset means that...';
      }
    },
    options1() {
      if (this.questionNumber.value === 1) {
        return [
          {
            text: 'Neutralize all solutions before working with them',
            value: '1',
          },
          {
            text: 'Wear safety glasses',
            value: '2',
          },
          {
            text: 'Wear safety gloves',
            value: '3',
          },
          {
            text: 'Dilute strong acid solutions with weak acids before working with them',
            value: '4',
          },
          {
            text: 'Wear a lab coat',
            value: '5',
          },
        ];
      } else if (this.questionNumber.value === 2) {
        return [
          {text: 'Wear close-toed shoes', value: '1'},
          {text: 'Wear safety glasses', value: '2'},
          {text: 'Wear lab coats', value: '3'},
          {text: 'Tie back long hair', value: '4'},
        ];
      } else if (this.questionNumber.value === 3) {
        return [
          {text: 'The laboratory', value: '1'},
          {text: 'The computer room', value: '2'},
          {text: 'The washrooms', value: '3'},
          {text: 'The office area', value: '4'},
        ];
      } else if (this.questionNumber.value === 4) {
        return [
          {
            text: 'Be aware of your surroundings',
            value: '1',
          },
          {
            text: 'Make new friends',
            value: '2',
          },
          {
            text: 'Be late',
            value: '3',
          },
          {
            text: "Listen carefully to your teacher's instructions",
            value: '4',
          },
          {
            text: "Ask questions if you don't understand something",
            value: '5',
          },
          {
            text: "Don't keep things clean and tidy while working",
            value: '6',
          },
        ];
      } else {
        return [
          {
            text: 'Safety always comes first',
            value: '1',
          },
          {
            text: "Experiments must be designed and carried out to ensure everyone's safety",
            value: '2',
          },
          {
            text: 'Accidents can be prevented',
            value: '3',
          },
          {
            text: 'Some accidents are unavoidable',
            value: '4',
          },
        ];
      }
    },
  },
};
</script>
